.pagination {
  display: flex;
  border-width: 1px;
  border-color: #b2c9f3;
  justify-content: space-between;
  border-radius: 25px;
  font-size: 14px;
  flex: 0 1 auto;
  flex-wrap: wrap;
}

.pagination li {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: black;
}

.pagination a {
  color: black;
}

.pagination .active {
  color: rgba(227, 60, 61, 0.3);
  font-weight: 600;
  border-color: #b2c9f3;
  border-left-width: 1px;
  border-right-width: 1px;
}

.pagination .previous {
  margin-right: 10px;
  padding-left: 15px;
}

.pagination .next {
  margin-left: 10px;
  padding-right: 15px;
}
